<template>
  <div>
    <div class="air__utils__heading">
      <h5>Administración</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">

      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: ''}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="gear-wide-connected" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: ''}">
              <h5 class="text-block text-secondary">Facturación de servicios</h5>
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: ''}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="file-binary" />
                </b-iconstack>
              </router-link>
            </h1>
          </div> -->
          <!-- <div class="card-body text-center">
            <router-link :to="{ name: ''}">
              <h5 class="text-block text-secondary">Reportes</h5>
            </router-link>
          </div> -->
        <!-- </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'warehouses'}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="box-seam" />
                </b-iconstack>
              </router-link>
            </h1>
          </div> -->
          <!-- <div class="card-body text-center">
            <router-link :to="{ name: 'warehouses'}">
              <h5 class="text-block text-secondary">Almacenes</h5>
            </router-link>
          </div> -->
        <!-- </div>
      </div> -->
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: ''}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="graph-up" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: ''}">
              <h5 class="text-block text-secondary">Resultados</h5>
            </router-link>
          </div>
        </div>
      </div>

    </div>
    <!--    Enlaces-->

  </div>
</template>

<script>

export default {
  name: 'menuExcelForm',
  components: {

  },

  computed: {
    // ...mapState(['servicePlannings']),
  },
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
        },
      ],
      resource: '',
      resourceName: 'Servicios',
      searchInput: null,
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
